import "core-js/stable";
import "regenerator-runtime/runtime";

window.wrtrack = {
    settings: {},
    init: config => {
        //alert("init");
        wrtrack.settings = {
            // the name of the cookie to set
            cookieName:
                config && config.cookieName ? config.cookieName : "wr__consent",
            // whether it's on, accepted, etc
            cookieFlag: config && config.cookieFlag ? config.cookieFlag : "on",
            // how often they need to click it again
            cookieMaxAge:
                config && config.cookieMaxAge ? config.cookieMaxAge : 14,
            // the id of the div to hide
            alertSelector:
                config && config.alertSelector
                    ? config.alertSelector
                    : "#o-cookie-warning",
            // the "ok" button selector
            alertCloseSelector:
                config && config.alertCloseSelector
                    ? config.alertCloseSelector
                    : "#cookie-consent-button",
            // the name of the event to raise when closed
            consentGrantedCallback:
                config && config.consentGrantedCallback
                    ? config.consentGrantedCallback
                    : () => {
                          console.log("no consent callback");
                      }
        };

        if (!wrtrack.checkCookie()) {
            wrtrack.showAlert();
        } else {
            wrtrack.consentGranted();
        }
    },

    checkCookie: () => {
        const cookies = document.cookie.split("; ");
        let cookiesCount = cookies.length;
        const cookieToCheck = `${wrtrack.settings.cookieName}=${wrtrack.settings.cookieFlag}`;

        while (cookiesCount--) {
            if (cookies[cookiesCount] === cookieToCheck) {
                return true;
            }
        }

        return false;
    },

    showAlert: () => {
        const element = document.querySelector(wrtrack.settings.alertSelector);
        const closeElement =
            element &&
            element.querySelector(wrtrack.settings.alertCloseSelector);

        if (closeElement) {
            closeElement.addEventListener("click", wrtrack.hideAlert, false);
        }

        if (element) {
            element.classList.remove("display-none");
            element.classList.add("show-cookie-popup");
        }
    },

    setCookie: () => {
        let expires = "";
        const date = new Date();

        date.setTime(
            date.getTime() + wrtrack.settings.cookieMaxAge * 24 * 60 * 60 * 1000
        );
        expires = `; expires=${date.toUTCString()}`;
        const cookie = `${wrtrack.settings.cookieName}=${wrtrack.settings
            .cookieFlag || ""}${expires}; path=/`;
        document.cookie = cookie;
    },

    removeCookie: () => {
        document.cookie = wrtrack.settings.cookieName.concat("=; max-age=-1;");
    },

    hideAlert: e => {
        e.preventDefault();

        const element = document.querySelector(wrtrack.settings.alertSelector);

        element.classList.remove("show-cookie-popup");

        wrtrack.setCookie();
        wrtrack.consentGranted();
    },

    consentGranted: () => {
        if (
            wrtrack.settings.consentGrantedCallback &&
            typeof wrtrack.settings.consentGrantedCallback === "function"
        ) {
            wrtrack.settings.consentGrantedCallback();
        }
    }
};
